
/**
 * Section Administration tab for the information modal
 *
 * @author Ibomade GmbH <Ibomade GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { CwuDisplaySettings } from "@/model/ModelSectionState";
import { ActionMessage } from "@/model/Messages/statusMessage";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import Section from "@/model/ModelSection";
import Project from "@/model/Project";
import VueI18n, { TranslateResult } from "vue-i18n";
import { ProjectSectionDetails } from "@/services/projectService"

@Component({
  name: "AdministrationTab",
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentSectionDetails: "GetCurrentSectionDetails"
    })
  },
  methods: {
    ...mapActions("projects", {
      GET_SECTION_DETAILS: "GetSectionDetail",
      SET_CUSTOM_FILENAMES: "SetCwuCustomFilenameSettings",
      SET_SUPPORT_ENABLED: "SetSupportEnabledSectionState"
    }),
    ...mapActions(["rebootRouter"])
  }
})
export default class AdministrationTab extends Mixins(confirmModal) {
  /*----------  Props  ----------*/
  @Prop({ required: true, type: String }) boardPosition!: string;
  $t!: VueI18n["t"];
  /*----------  vuex  ----------*/
  readonly currentSectionDetails!: Section;
  readonly currentProject!: Project;
  cwuCustomFilenameSettings: any = new CwuDisplaySettings();
  actionMessage: ActionMessage | null = null;

  isBusy = false;
  isLoading = false;

  private SET_CUSTOM_FILENAMES!: Function;
  private GET_SECTION_DETAILS!: Function;
  private SET_SUPPORT_ENABLED!: Function;
  private rebootRouter!: Function;

  /*----------  Local data  ----------*/

  /*=============================================
  =            Vue life cycles                =
  =============================================*/

  /*----------  Watch  ----------*/
  @Watch("currentSectionDetails", { immediate: true })
  HandleCurrentSectionWatch() {
    this.formatCustomFilenameTraffic();
    this.formatCustomFilenameTrafficRisk();
    this.formatCustomFilenameOff();
  }

  get stateModel(): "deviceStateOne" | "deviceStateTwo" {
    switch (this.boardPosition) {
      case "deviceOne":
        return "deviceStateOne";
      case "deviceTwo":
        return "deviceStateTwo";
      default:
        return "deviceStateOne";
    }
  }
  get desiredSettings(): CwuDisplaySettings | null {
    return this.currentSectionDetails.state.cwuSettings;
  }

  get customFilenameTrafficState() {
    const filename = this.currentSectionDetails.state.cwuSettings?.customFilenameTraffic;
    if (!filename) return true;
    return !(filename.indexOf(" ") >= 0 || filename.length > 8 || filename.length <= 0);
  }

  get customFilenameTrafficRiskState() {
    const filename = this.currentSectionDetails.state.cwuSettings?.customFilenameTrafficRisk;
    if (!filename) return true;
    return !(filename.indexOf(" ") >= 0 || filename.length > 8 || filename.length <= 0);
  }

  get customFilenameOffState() {
    const filename = this.currentSectionDetails.state.cwuSettings?.customFilenameOff;
    if (!filename) return true;
    return !(filename.indexOf(" ") >= 0 || filename.length > 8 || filename.length <= 0);
  }

  get isFormInvalid() {
    if (this.currentSectionDetails.state.cwuSettings?.customFilenameEnabled) {
      return !this.customFilenameTrafficState || !this.customFilenameTrafficRiskState || !this.customFilenameOffState;
    } else return false;
  }

  /*----------  Computed  ----------*/

  /*----------  Methods  ----------*/
  formatCustomFilenameOff() {
    if (this.currentSectionDetails.state.cwuSettings) {
      this.currentSectionDetails.state.cwuSettings.customFilenameOff =
        this.currentSectionDetails.state.cwuSettings?.customFilenameOff?.replace(/.bmp/g, "").replace(/.gif/g, "") ||
        "";
    }
  }

  formatFileName(fileName: string, defaultFileName: string) {
    let formattedFileName: string | TranslateResult = fileName;
    if (!formattedFileName && formattedFileName !== "")
      formattedFileName = this.$t(
        `project.section.informationModal.administrationTab.${defaultFileName}DefaultFileName`
      );

    formattedFileName = formattedFileName.toString().replace(/.bmp/g, "").replace(/.gif/g, "");

    return formattedFileName ? `${formattedFileName}.bmp` : "";
  }

  formatCustomFilenameTraffic() {
    if (this.currentSectionDetails.state.cwuSettings)
      this.currentSectionDetails.state.cwuSettings.customFilenameTraffic =
        this.currentSectionDetails.state.cwuSettings.customFilenameTraffic?.replace(/.bmp/g, "").replace(/.gif/g, "");
  }

  formatCustomFilenameTrafficRisk() {
    if (this.currentSectionDetails.state.cwuSettings)
      this.currentSectionDetails.state.cwuSettings.customFilenameTrafficRisk =
        this.currentSectionDetails.state.cwuSettings.customFilenameTrafficRisk
          ?.replace(/.bmp/g, "")
          .replace(/.gif/g, "");
  }

  async UpdateDeviceSettings(call: Function, callOptions: unknown) {
    try {
      const res = await call(callOptions);
      if (res.status === 200) {
        this.actionMessage = {
          class: "success",
          msg: this.$t("project.section.informationModal.confirmModals.msgSetMeasureCameraSettingsSuccess")
        };
      } else {
        this.actionMessage = {
          class: "danger",
          msg: res
        };
      }
    } catch (err) {
      this.actionMessage = {
        class: "danger",
        msg: err as TranslateResult
      };
    } finally {
      this.isBusy = false;
    }
  }

  // async handleUpdateActivationFilename(filenameEnabled: boolean) {
  async settingsUpdate(acceptCall: Function, cancelCall: Function) {
    this.actionMessage = null;
    this.confirm(
      this.$t("project.section.informationModal.confirmModals.msgSetMeasureCameraSettings").toString(),
      acceptCall,
      cancelCall
    );
  }

  async handleToggleActivationFilename() {
    await this.settingsUpdate(async () => {
      this.isBusy = true;
      const cwuCustomFilenameSettings = {
        customFilenameEnabled: this.currentSectionDetails.state.cwuSettings?.customFilenameEnabled,
        customFilenameTraffic: this.formatFileName(
          this.currentSectionDetails.state.cwuSettings?.customFilenameTraffic || "",
          "traffic"
        ),
        customFilenameTrafficRisk: this.formatFileName(
          this.currentSectionDetails.state.cwuSettings?.customFilenameTrafficRisk || "",
          "trafficRisk"
        ),
        customFilenameOff: ""
      };
      const callOptions = {
        sectionId: this.currentSectionDetails.id,
        cwuCustomFilenameSettings
      };
      await this.UpdateDeviceSettings(this.SET_CUSTOM_FILENAMES, callOptions);

      await this.resetCustomFilenameState();
    }, this.resetCustomFilenameState);
  }

  async handleUpdateActivationFilename(filenameEnabled: boolean) {
    await this.settingsUpdate(async () => {
      this.isBusy = true;
      const cwuCustomFilenameSettings = {
        customFilenameEnabled: filenameEnabled,
        customFilenameTraffic: "Stau.bmp",
        customFilenameTrafficRisk: "Stgefahr.bmp",
        customFilenameOff: ""
      };
      const callOptions = {
        sectionId: this.currentSectionDetails.id,
        cwuCustomFilenameSettings
      };

      await this.UpdateDeviceSettings(this.SET_CUSTOM_FILENAMES, callOptions);

      await this.resetCustomFilenameState();
    }, this.resetCustomFilenameState);
  }

  async handleChangeCustomFilename() {
    await this.settingsUpdate(async () => {
      this.isBusy = true;
      const cwuCustomFilenameSettings = {
        customFilenameEnabled: true,
        customFilenameTraffic: this.formatFileName(
          this.currentSectionDetails.state.cwuSettings?.customFilenameTraffic || "",
          "traffic"
        ),
        customFilenameTrafficRisk: this.formatFileName(
          this.currentSectionDetails.state.cwuSettings?.customFilenameTrafficRisk || "",
          "trafficRisk"
        ),
        customFilenameOff: this.formatFileName(
          this.currentSectionDetails.state.cwuSettings?.customFilenameOff || "",
          "off"
        )
      };
      const callOptions = {
        sectionId: this.currentSectionDetails.id,
        cwuCustomFilenameSettings
      };
      await this.UpdateDeviceSettings(this.SET_CUSTOM_FILENAMES, callOptions);

      await this.resetCustomFilenameState();
    }, this.resetCustomFilenameState);
  }

  async handleToggleSupportEnabled(state: boolean) {
    await this.settingsUpdate(async () => {
      this.isBusy = true;
      this.currentSectionDetails.state.stateDetails[this.stateModel].supportEnabled = state;

      const sectionState = this.currentSectionDetails.state;

      const callOptions = {
        sectionId: this.currentSectionDetails.id,
        sectionState
      };
      await this.UpdateDeviceSettings(this.SET_SUPPORT_ENABLED, callOptions);

      await this.resetCustomFilenameState();

      if(state) {

        var data = {
          sectionId: this.currentSectionDetails.id,
          boardId: this.currentSectionDetails.state.stateDetails[this.stateModel].mainboardUid,
          data: this.currentSectionDetails.state.stateDetails[this.stateModel]
        }

        await ProjectSectionDetails.sendSupportNotification(data);
      }

    }, this.resetCustomFilenameState);
  }

  async resetCustomFilenameState() {
    await this.GET_SECTION_DETAILS(this.currentSectionDetails.id);
    if (this.currentProject.isCWU) {
      this.formatCustomFilenameTraffic();
      this.formatCustomFilenameTrafficRisk();
      this.formatCustomFilenameOff();
    }
  }
  /*----------  Helper functions  ----------*/
  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("inventory.deviceActions.archiveDevice.modalTitle"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          action();
        }
      });
  }

  async rebootHandlerAdminTab(serialNumber: string) {
    try {
      this.isBusy = true;
      this.confirmModal((this as any).$t("inventory.deviceActions.rebootRouter"), () => {
        this.rebootRouter(serialNumber).then((res: any) => {
          if (res.data === true) {
            this.isBusy = false;
            this.actionMessage = {
              class: "success",
              msg: this.$t("project.section.informationModal.confirmModals.msgrebootRouterSuccess")
            };
          } else {
            this.isBusy = false;
            this.actionMessage = {
              class: "danger",
              msg: this.$t("project.section.informationModal.confirmModals.msgrebootRouterFailed")
            };
          }
        });
      });
    } catch (err) {
      this.actionMessage = {
        class: "danger",
        msg: this.$t("project.section.informationModal.confirmModals.msgrebootRouterFailed")
      };
    } finally {
      this.isBusy = false;
    }
  }
}
