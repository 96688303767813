
import Project from "@/model/Project";
import { TableFields } from "@/model/Tables/TableFields";
/**
 * Compare the actual state to the current state
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Section from "@/model/ModelSection";

@Component({
  name: "DesireStateVSActualStateTable",
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentSectionDetails: "GetCurrentSectionDetails"
    })
  }
})
export default class DesireStateVSActualStateTable extends Vue {
  @Prop({ type: Object, required: true, default: () => ({}) })
  desiredState!: { [key: string]: any[] };
  @Prop({ type: Object, required: true, default: () => ({}) })
  actualState!: { [key: string]: any[] };
  @Prop({ type: Number, default: 0 })
  slaveBoardId!: number;
  @Prop({ }) section!: any;

  private readonly currentProject!: Project;
  private readonly currentSectionDetails!: Section;

  fields: Array<TableFields> = [
    {
      key: "description",
      sortable: true,
      label: this.translateTableLabel("description")
    },
    {
      key: "desiredState",
      sortable: true,
      label: this.translateTableLabel("desiredState")
    },
    {
      key: "actualState",
      sortable: true,
      label: this.translateTableLabel("actualState")
    },
    {
      key: "status",
      sortable: true,
      label: this.translateTableLabel("status")
    }
  ];
  rows = ["picture", "pictureTop", "pictureMid", "pictureBottom", "pictureText", "pictureTextSize", "brightness", "brightnessAuto", "size", "slaveboardPosition", "slaveboardStatus"];

  created() {
    if(!this.currentSectionDetails.isVwa) {
      this.rows = ["picture", "brightness", "brightnessAuto", "size", "slaveboardPosition", "slaveboardStatus"];
    }
  }

  translateTableLabel(label: string) {
    return this.$t(`project.section.informationModalTableLabel.${label}`);
  }
  get formatCheckInformation() {

    if (!this.currentProject.isFPU || this.desiredState.brightnessAuto) {
      this.rows = this.rows.filter((row) => row !== "brightness");
    }

    if(this.currentSectionDetails.isVwa) {

      this.rows = ["picture", "pictureTop", "pictureMid", "pictureBottom", "pictureText", "pictureTextSize", "size", "slaveboardPosition", "slaveboardStatus"];

      var vwaSettings = this.currentSectionDetails.state.vwaSettings;

      // Full Size IMG
      if(vwaSettings?.filename != '') {
        this.rows = this.rows.filter((row) => row !== "pictureTop" && row !== "pictureMid" && row !== "pictureBottom" && row !== "pictureText" && row !== "pictureTextSize");

      // T/M/D IMG
      } else if ( vwaSettings.filenameTop != '' || vwaSettings.filenameMid != '' || vwaSettings.filenameBottom != '' ) {
        this.rows = this.rows.filter((row) => row !== "picture" && row !== "pictureText" && row !== "pictureTextSize");

      } else if(vwaSettings.text != '' || vwaSettings.textSize != 0) {
        this.rows = this.rows.filter((row) => row !== "pictureTop" && row !== "pictureMid" && row !== "pictureBottom" && row !== "picture");

      }
    }

    return this.rows.map((label) => ({
      description: this.$t(`project.section.informationModalTableLabel.${label}Row`),
      desiredState: this.desiredState[label],
      actualState: this.actualState[label],
      status: this.desiredState[label] === this.actualState[label]
    }));
  }
}
