
import SectionState from "@/model/ModelSectionState";
/**
 * Description
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
@Component({
  name: "SectionRawDataTab",
  computed: {
    ...mapGetters("projects", {
      currentSectionState: "GetCurrentSectionState"
    })
  }
})
export default class SectionRawDataTab extends Vue {
  /*----------  Props  ----------*/
  @Prop({ type: String, default: "", required: true })
  boardPosition!: string;

  /*----------  Vuex  ----------*/
  private readonly currentSectionState!: SectionState;

  get rawDataFromSelectedBoard() {
    if (this.currentSectionState.stateDetails) {
      switch (this.boardPosition) {
        case "deviceOne":
          return this.currentSectionState.stateDetails.deviceStateOne.rawInfo;
        case "deviceTwo":
          return this.currentSectionState.stateDetails.deviceStateTwo.rawInfo;
        default:
          return "{}";
      }
    } else return "{}";
  }
  formatJson() {
    return JSON.parse(this.rawDataFromSelectedBoard);
  }

  async copyToClipboard() {
    try {
      let rawInformationToCopy = await this.rawDataFromSelectedBoard;
      await navigator.clipboard.writeText(rawInformationToCopy);
      this.$bvToast.toast(`Raw data Copying to clipboard was successful!`, {
        title: "Copy raw data to clipboard",
        variant: "success",
        autoHideDelay: 1000,
        appendToast: true
      });
    } catch (error) {
      this.$bvToast.toast(`Could not copy raw data: ${error}`, {
        title: "Raw data to clipboard",
        variant: "danger",
        autoHideDelay: 1000,
        appendToast: true
      });
    }
  }
}
