

import { Component, Vue, Prop} from "vue-property-decorator";
import {TableFields} from "@/model/Tables/TableFields";
import {SingleDeviceState} from "@/model/ModelSectionState";
@Component({
  name: "InformationVWA",
})
export default class InformationVWA extends Vue {
  webUrl = process.env.VUE_APP_WEB_URL;

  @Prop({}) mainBoardReasons!: any;

}
