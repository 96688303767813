
/**
 * Information Modal for each board and all the section settings
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";

import InformationTab from "@/components/project/SingleProject/Schematic/InformationModal/InformationTab.vue";
import SectionSettingsTab from "@/components/project/SingleProject/Schematic/InformationModal/SectionSettingsTab.vue";
import SectionRawDataTab from "@/components/project/SingleProject/Schematic/InformationModal/SectionRawDataTab.vue";
import AdministrationTab from "@/components/project/SingleProject/Schematic/InformationModal/AdministrationTab.vue";
import MetricsTab from "@/components/project/SingleProject/Schematic/InformationModal/MetricsTab.vue";
import IOTab from "@/components/project/SingleProject/Schematic/InformationModal/IOTab.vue";

import Section from "@/model/ModelSection";
import SectionState from "@/model/ModelSectionState";
import Project from "@/model/Project";
import UserAccess from "@/model/User/UserAccess";
import { ProjectTypeInterfaceInformationModal } from "@/model/MainProject/MainProjectSection";
import VueI18n from "vue-i18n";

@Component({
  name: "SingleBoardInformationModal",
  methods: {
    ...mapMutations("projects", {
      RESET_INFORMATION_MODAL: "ResetInformationModal",
      RESET_CAMERA_PICTURE: "ResetCameraSectionLatestPicture"
    }),
    ...mapActions("projects", {
      refreshSectionAllSections: "GetAllSectionFromTheProject",
      getSectionDetailsFromBackEnd: "GetSectionDetail",
      GetCurrentSectionState: "GetCurrentSectionState",
      GET_LATEST_COUNTER: "GetTrafficCounter"
    })
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentSectionState: "GetCurrentSectionState",
      currentSectionDetails: "GetCurrentSectionDetails"
    })
  },
  components: {
    InformationTab,
    SectionSettingsTab,
    SectionRawDataTab,
    AdministrationTab,
    MetricsTab,
    IOTab
  }
})
export default class SingleBoardInformationModal extends Vue {
  $t!: VueI18n["t"];
  /*----------  Component  props section id and the board position ----------*/
  @Prop({ required: true, type: Number }) sectionId!: number;
  @Prop({ required: true, type: String }) boardPosition!: string;
  @Prop({ type: Boolean, default: false }) isDashboardModal!: boolean;
  @Prop({ type: Boolean, default: false }) trafficReport!: boolean;
  @Prop({ type: Object as () => ProjectTypeInterfaceInformationModal })
  projectType!: ProjectTypeInterfaceInformationModal;

  /*----------  Vuex   ----------*/
  private GetCurrentSectionState!: Function;
  private getSectionDetailsFromBackEnd!: Function;
  private refreshSectionAllSections!: Function;
  private RESET_INFORMATION_MODAL!: Function;
  private RESET_CAMERA_PICTURE!: Function;
  private GET_LATEST_COUNTER!: Function;

  private readonly currentProject!: Project;
  readonly currentSectionDetails!: Section;
  readonly currentSectionState!: SectionState;

  readonly getUserAccess!: UserAccess;

  deviceId = 0;

  /*----------  Local data  ----------*/
  isLoading: boolean = true;

  intervalLatestInformation: Array<number> = [];
  metricDataIntervalId = 0;
  /*----------  Vue life-cycles  ----------*/

  created() {

    setTimeout(() => {

      // Überprüfe, ob das DOM-Element existiert, bevor die Klasse hinzugefügt wird
      const tabElement = document.getElementById('informationTab');
      if (tabElement) {
        tabElement.classList.add('active');
      }

    }, 2000)

  }

  /*----------  computed  ----------*/
  get haveSectionIdAndDevicePosition() {
    return this.sectionId !== 0 && this.boardPosition !== "";
  }

  /*----------  Methods  ----------*/

  async GET_SECTION_INFORMATION() {
    this.isLoading = true;
    if (!this.isDashboardModal) this.refreshSectionAllSections(this.currentProject.id);

    if (this.haveSectionIdAndDevicePosition) {
      let getSecInfo = await this.getSectionDetailsFromBackEnd(this.sectionId);
      let sectionState = await this.GetCurrentSectionState(this.sectionId);
      if (this.currentSectionDetails.isMeasure) {
        await this.GET_LATEST_COUNTER({
          sectionId: this.currentSectionDetails.id,
          projectVolume: this.currentProject.volumeAnalysisVariant?.name
        });
      }

      if (getSecInfo.status === 200 || sectionState.status === 200) {
        this.deviceId = this.boardPosition === "deviceOne" ? getSecInfo.data.boardOne : getSecInfo.data.boardTwo; // Show something to the user that he is view in the latest information in the modal
        this.isLoading = false;

        if(this.currentSectionDetails.type && this.currentSectionDetails.type.name == 'vwa') {
          this.getUserAccess.Access.VIEW.project.vwa.settings = true;
        } else {
          this.getUserAccess.Access.VIEW.project.vwa.settings = false;
        }
      }
    }
  }

  async refreshSectionInformation() {
    const shouldReturn =
      this.currentProject.isArchived ||
      !this.haveSectionIdAndDevicePosition ||
      this.intervalLatestInformation.length > 1;

    if (shouldReturn) return;

    let getStateEvery = 10 * 1000;
    let newInterval = setInterval(async () => {
      this.isLoading = true;
      await this.getSectionDetailsFromBackEnd(this.sectionId);
      await this.GetCurrentSectionState(this.sectionId);
      if (this.currentSectionDetails.isMeasure) {
        await this.GET_LATEST_COUNTER({
          sectionId: this.sectionId,
          projectVolume: this.currentProject.volumeAnalysisVariant?.name
        });
      }
      this.isLoading = false;
    }, getStateEvery);

    this.intervalLatestInformation.push(newInterval);
  }

  async clearInterval() {
    let promises = this.intervalLatestInformation.map(async (intervalId: number) => {
      clearInterval(intervalId);
    });
    await Promise.all(promises);
    this.intervalLatestInformation = [];
  }

  async handleModalClose() {
    this.RESET_INFORMATION_MODAL();
    this.RESET_CAMERA_PICTURE();
    this.$emit("infoModalClose");
    await this.clearInterval();
  }

  checkCurrentTab(index: number) {
    this.getSectionDetailsFromBackEnd(this.sectionId);
    if (index === 0) {
      this.refreshSectionInformation();
    } else {
      this.clearInterval();
    }
    index !== 4 && this.metricDataIntervalId
      ? (clearInterval(this.metricDataIntervalId), (this.metricDataIntervalId = 0))
      : null;
  }

  refreshSchematicView() {
    this.$emit("refresh-schematic-view");
  }
  HandleNewIntervalForMetricData(intervalId: number) {
    this.metricDataIntervalId = intervalId;
  }
}
