
/**
 * Display interval Modal the create interval,
 * Create interval
 * Show the interval table
 This component is how in the information modal two
 Whit out the select profile dropdown
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import Section from "@/model/ModelSection";
import { Interval, ProjectProfile } from "@/interface/projectsInterface";
import Project from "@/model/Project";
import IntervalDetailsTable from "@/components/project/SingleProject/Profiles/IntervalDetailsTable.vue";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import UserAccess from "@/model/User/UserAccess";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { WEP_MOCK_URL } from "@/shared/keycloak";

interface LocalManualOverride {
  filename: any;
  manualOverride: boolean;
}

@Component({
  name: "DisplayModalInterval",
  components: {
    Multiselect,
    IntervalDetailsTable
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      intervalList: "GetSectionIntervalList",
      currentSection: "GetCurrentSectionDetails",
      currentProjectInformation: "GetCurrentProject",
      manualOverrideOptions: "GetManualOverrideOptionsFromState"
    }),
    ...mapGetters("ProjectProfilesStore", ["GetAllProfilesForCurrentProject", "GetCurrentSectionProfile"])
  },
  methods: {
    ...mapMutations("ProjectProfilesStore", ["RESET_SECTION_PROFILE"]),
    ...mapMutations("projects", ["ResetSectionDetail"]),
    ...mapActions("projects", {
      manualOverrideToggle: "manualOverrideToggle",
      getSectionsDetails: "GetSectionDetail",
      GetManualOverrideSignOption: "GetManualOverrideSingOptions",
      SetSignOverride: "SetSignOverride"
    }),
    ...mapActions("ProjectProfilesStore", ["GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT", "GET_CURRENT_SECTION_PROFILE"])
  }
})
export default class DisplayModalInterval extends Mixins(confirmModal) {
  WEP_MOCK_URL = WEP_MOCK_URL;
  // Vuex
  readonly getUserAccess!: UserAccess;
  readonly currentSection!: Section;
  readonly intervalList!: Array<Interval>;
  readonly currentProjectInformation!: Project;
  readonly manualOverrideOptions!: Array<object>;

  readonly GetAllProfilesForCurrentProject!: Array<object>;
  readonly GetCurrentSectionProfile!: ProjectProfile;

  private manualOverrideToggle!: Function;
  private getSectionsDetails!: Function;
  private GetManualOverrideSignOption!: Function;
  private SetSignOverride!: Function;

  private GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT!: Function;
  private GET_CURRENT_SECTION_PROFILE!: Function;
  private RESET_SECTION_PROFILE!: Function;
  private ResetSectionDetail!: Function;

  @Prop({ type: Object, default: () => new Section() })
  sectionInformation!: Section;
  @Prop({ type: Number, default: null }) displayId!: number;
  @Prop({ type: Boolean, default: false }) isInfoModal!: boolean;
  @Prop({ type: String, default: "48x48" }) displaySize!: string;

  /*----------  Local data  ----------*/
  formErrors: boolean = false;
  localManualOverride: null | LocalManualOverride = null;
  intervalUpdateStatus: null | ActionMessage = null;
  isLoading: boolean = false;

  selectedProfile: null | ProjectProfile = null;

  // localWeekDays
  created() {
    if (this.sectionInformation && !this.isInfoModal) {
      this.GetManualOverrideSignOption(this.sectionInformation.id);
      this.getSectionsDetails(this.sectionInformation.id)
        .then(() => {
          this.localManualOverride = this.currentSection.state.vsuSettings;
        })
        .then(() => {
          this.formatManualOverrideName();
        });
    }
  }

  @Watch("GetCurrentSectionProfile", { immediate: true })
  HandleChangeSectionProfile(val: null | ProjectProfile) {
    if (val?.projectId === "0") {
      this.selectedProfile = null;
    } else {
      this.selectedProfile = val;
    }
  }

  mounted() {
    if (this.sectionInformation && !this.isInfoModal) {
      this.getSectionsDetails(this.sectionInformation.id)
        .then(async () => {
          if (this.currentSection.state.vsuSettings) {
            this.localManualOverride = this.currentSection.state.vsuSettings;
            await this.GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT(this.currentProjectInformation.id);
            // Get section profile
            this.GET_CURRENT_SECTION_PROFILE(this.currentSection.id);
          }
        })
        .then(() => {
          this.formatManualOverrideName();
        });
    }
    if (this.isInfoModal) {
      this.GET_CURRENT_SECTION_PROFILE(this.currentSection.id);
    }
  }

  async beforeDestroy() {
    await this.ResetSectionDetail();
    this.RESET_SECTION_PROFILE();
  }

  async resetDisplayLocalState() {
    const sectionDetails = await this.getSectionsDetails(this.currentSection.id);
    if (sectionDetails.status === 200) {
      this.localManualOverride = this.currentSection.state.vsuSettings;
      this.formatManualOverrideName();
      this.refreshSchematicView();
    }
    this.isLoading = false;
  }

  // Handle manual override
  handleManualOverride() {
    if (this.currentSection.state.vsuSettings.manualOverride) {
      if (this.localManualOverride) {
        this.localManualOverride.filename = null;
      }
    } else this.selectedProfile = null;

    if (!this.localManualOverride) {
      this.intervalUpdateStatus = null;
      this.confirm(
        this.$t("project.section.displayInterval.confirmModals.msgToggleOnOverride", {
          overrideStatus: this.currentSection.state.vsuSettings.manualOverride
        }).toString(),
        async () => {
          this.isLoading = true;
          let profileId = this.currentSection.state.vsuSettings.switchingProfile?.id || null;
          let MOSettings = {
            id: this.currentSection.state.vsuSettings.id,
            manualOverride: this.currentSection.state.vsuSettings.manualOverride,
            //@ts-ignore
            filename: this.currentSection.state.vsuSettings.filename?.value || "",
            cbRadioEnabled: this.currentSection.state.vsuSettings.cbRadioEnabled,
            prewarningEnabled: this.currentSection.state.vsuSettings.prewarningEnabled,
            profileId
          };
          let res = await this.manualOverrideToggle({
            sectionId: this.currentSection.id,
            MOSettings
          });
          if (res.status === 200) {
            this.resetDisplayLocalState();
            this.intervalUpdateStatus = {
              class: "success",
              msg: this.currentSection.state.vsuSettings.manualOverride
                ? this.$t("project.section.displayInterval.confirmModals.msgManualSuccessMsg")
                : this.$t("project.section.displayInterval.confirmModals.msgAutoSuccessMsg")
            };
          } else if (res.response.status === 400) {
            this.intervalUpdateStatus = {
              class: "warning",
              msg: (this as any).$t("archiveProjects.actions.action400res")
            };
            this.isLoading = false;
            this.getSectionsDetails(this.sectionInformation.id);
          } else {
            this.isLoading = false;
            this.intervalUpdateStatus = {
              class: "danger",
              msg: res.response.data.message
            };
          }
        },
        async () => {
          this.isLoading = false;
          await this.getSectionsDetails(this.currentSection.id);
          return;
        }
      );
    }
  }

  HandleAddCustomImage(customFilename: string, settings: any) {
    let filename = customFilename.replace(/.bmp/g, "").replace(/.gif/g, "");
    settings.filename = {
      name: filename,
      value: `${filename}.bmp`
    };
    //this.handleSignChange();
  }

  handleSignChange() {
    this.isLoading = true;
    this.intervalUpdateStatus = null;
    this.closeProfileOrSignMultiSelect();
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgSetSign", {
        sign: this.localManualOverride?.filename.name
      }).toString(),
      async () => {
        let profileId = this.currentSection.state.vsuSettings.switchingProfile?.id || null;

        const manualOverrideSettings = {
          id: this.currentSection.state.vsuSettings.id,
          manualOverride: true,
          filename: this.localManualOverride?.filename.value,
          cbRadioEnabled: this.currentSection.state.vsuSettings.cbRadioEnabled,
          prewarningEnabled: this.currentSection.state.vsuSettings.prewarningEnabled,
          profileId
        };
        let res = await this.manualOverrideToggle({
          MOSettings: manualOverrideSettings,
          sectionId: this.currentSection.id
        });

        if (res.status === 200) {
          this.intervalUpdateStatus = {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgSetSignSuccess", {
              sign: this.localManualOverride?.filename.name
            })
          };
          this.resetDisplayLocalState();
        } else {
          this.isLoading = false;
          this.intervalUpdateStatus = {
            class: "danger",
            msg: res
          };
          this.resetDisplayLocalState();
        }
      },
      () => {
        this.resetDisplayLocalState();
      }
    );
  }
  closeProfileOrSignMultiSelect() {
    if (!this.localManualOverride?.manualOverride) {
      // close multiselect after select a profile
      let element: HTMLElement = document.getElementById("selectedProfile") as HTMLElement;
      element.focus();
      element.blur();
    } else {
      // close multiselect after select a sign
      let element: HTMLElement | any = document.getElementById(String(this.localManualOverride)) as HTMLElement | any;
      element.focus();
      element.blur();
    }
  }

  formatManualOverrideName() {
    if (this.localManualOverride && typeof this.localManualOverride.filename !== "object") {
      let isActive = this.localManualOverride.filename === "";
      let translatedEmptyImage = this.$t("project.section.displayInterval.filenames.empty");
      let haveTranslations = new Array(...(this as any).$t("project.section.displayInterval.displaySignOptions")).some(
        (item) => item.value === this.localManualOverride?.filename
      );

      if (!haveTranslations) {
        this.localManualOverride.filename = {
          name: isActive ? translatedEmptyImage : this.localManualOverride.filename.replace(".bmp", ""),
          value: this.localManualOverride.filename
        };
      } else {
        this.localManualOverride.filename = {
          name: isActive
            ? translatedEmptyImage
            : this.$t(
                `project.section.displayInterval.filenames.${this.localManualOverride.filename.replace(".bmp", "")}`
              ),
          value: this.localManualOverride.filename
        };
      }
    }
  }

  // Handle project profile change
  async handleOfProfileSelected() {
    this.closeProfileOrSignMultiSelect();
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.changeProfile").toString(),
      async () => {
        this.isLoading = true;
        this.intervalUpdateStatus = null;
        const manualOverrideSettings = {
          id: this.currentSection.state.vsuSettings.id,
          manualOverride: false,
          brightnessOverride: null,
          brightness: null,
          filename: "",
          profileId: this.selectedProfile?.id
        };

        let profileStatus = await this.manualOverrideToggle({
          MOSettings: manualOverrideSettings,
          sectionId: this.currentSection.id
        });

        if (profileStatus.status === 200) {
          this.intervalUpdateStatus = {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.setProfile")
          };
          this.resetDisplayLocalState();
        } else {
          this.isLoading = false;
          this.intervalUpdateStatus = {
            class: "danger",
            msg: profileStatus
          };
        }
      },
      async () => {
        await this.getSectionsDetails(this.currentSection.id);
        await this.GET_CURRENT_SECTION_PROFILE(this.currentSection.id);
        return;
      }
    );
  }

  handleManualToggle(manual: boolean) {
    if (this.currentProjectInformation.isArchived) return;

    this.currentSection.state.vsuSettings.manualOverride = manual;
    this.handleManualOverride();
  }

  refreshSchematicView() {
    this.$emit("refresh-schematic-view");
  }

  HandleUpdateVSUSettings() {
    this.intervalUpdateStatus = null;
    this.confirm(
      this.$t("projects.singleProjectView.displayState.displayConfirmModal.fpuConfirmUpdate").toString(),
      async () => {
        this.isLoading = true;
        let profileId = this.currentSection.state.vsuSettings.switchingProfile?.id || null;
        let MOSettings = {
          id: this.currentSection.state.vsuSettings.id,
          manualOverride: this.currentSection.state.vsuSettings.manualOverride,
          //@ts-ignore
          filename: this.currentSection.state.vsuSettings.filename?.value || "",
          prewarningEnabled: this.currentSection.state.vsuSettings.prewarningEnabled,
          cbRadioEnabled: this.currentSection.state.vsuSettings.cbradioEnabled,
          profileId
        };
        let res = await this.manualOverrideToggle({
          sectionId: this.currentSection.id,
          MOSettings
        });
        if (res.status === 200) {
          this.intervalUpdateStatus = {
            class: "success",
            msg: this.$t("projects.singleProjectView.displayState.displayConfirmModal.fpuUpdateSuccess").toString()
          };
          this.getSectionsDetails(this.sectionInformation.id);
          this.isLoading = false;
        } else if (res.response.status === 400) {
          this.intervalUpdateStatus = {
            class: "warning",
            msg: this.$t("archiveProjects.actions.action400res").toString()
          };
          this.isLoading = false;
          this.getSectionsDetails(this.sectionInformation.id);
        } else {
          this.isLoading = false;
          this.intervalUpdateStatus = {
            class: "danger",
            msg: res.response.data.message
          };
        }
      },
      () => {
        this.getSectionsDetails(this.sectionInformation.id);
      }
    );
  }
}
