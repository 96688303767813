
/**
 * Overview card for te information tab in the
 * Single device details view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { MainboardAndRouterUrl, OverViewLabels } from "@/model/SingleDeviceModal";
import project from "@/stores/modules/project";
import { Component, Prop, Vue } from "vue-property-decorator";

import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon } from "vue2-leaflet";
// import { mapGetters, mapActions } from "vuex";

@Component({
  name: "InformationTabOverviewCard",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon
  }
})
export default class InformationTabOverviewCard extends Vue {
  /*----------  Props  ----------*/
  @Prop({ required: true, type: Object as () => OverViewLabels }) OverviewInformation!: OverViewLabels;
  @Prop({ required: true, type: Object as () => MainboardAndRouterUrl | null }) url!: MainboardAndRouterUrl | null;
  @Prop({ required: true, type: String, default: "ALERT" }) deviceStatus!: string;

  mapTilesUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  zoom: number = 13;

  mapOptions = {
    zoomControl: false,
    dragging: false,
    closePopupOnClick: false,
    boxZoom: false,
    zoomDelta: false,
    scrollWheelZoom: false
  };

  ShouldBeRenderDefault(key: string) {
    const notRender = ["mainboardUid", "routerSerialNumber", "gpsPosition", "connectionQuality", "ping"];
    return notRender.includes(key);
  }
}
