
/**
 * Metric tab view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { ActionMessage } from "@/model/Messages/statusMessage";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";
import MetricChart from "@/views/charts/MetricChart.vue";
import Multiselect from "vue-multiselect";
import { TranslateResult } from "vue-i18n";

/* option for the view of year should be disabled for now #4419
type optionsValues = "1h" | "1d" | "1w" | "1m" | "1y";*/

type optionsValues = "1h" | "1d" | "1w" | "1m";

interface selectItem {
  label: TranslateResult;
  value: optionsValues;
}

interface ChartBaseStyles {
  batteryVoltage: DataPoints;
  solarArray: DataPoints;
  chargingCurrent: DataPoints;
  dischargingCurrent: DataPoints;
  brightness: DataPoints;
}

interface DataPoints {
  label: TranslateResult;
  data: Array<number>;
  fill: boolean;
  strokeColor: string;
  borderColor: string;
  backgroundColor: string;
  pointBackgroundColor: string;
  tension: number;
  pointStyle: string;
  pointRadius: number;
  pointHoverRadius: number;
  borderWidth: number;
  hidden: boolean;
}

@Component({
  name: "MetricsTab",
  components: {
    MetricChart,
    Multiselect
  },
  methods: {
    ...mapActions("Evaluator", {
      FETCH_DEVICE_HISTORICAL_DATA: "FETCH_DEVICE_HISTORICAL_DATA"
    }),
    ...mapMutations("Evaluator", {
      RESET_DEVICE_HISTORICAL_DATA: "RESET_DEVICE_HISTORICAL_DATA"
    })
  },
  computed: {
    ...mapGetters("Evaluator", {
      DEVICE_HISTORICAL_DATA: "GET_DEVICE_HISTORICAL_DATA"
    }),
    ...mapGetters("Theme", {
      CURRENT_THEME: "GET_CURRENT_THEME"
    })
  }
})
export default class MetricsTab extends Vue {
  readonly CURRENT_THEME!: string;
  @Prop({ required: true, type: String }) deviceId!: string;
  @Prop({ required: true, type: String }) boardPosition!: string;
  @Prop({ required: false, type: Number }) intervalId!: number;

  // eslint-disable-next-line no-unused-vars
  private FETCH_DEVICE_HISTORICAL_DATA!: (opt: { deviceId: string; timeFrame: string }) => void;
  private RESET_DEVICE_HISTORICAL_DATA!: () => void;
  readonly DEVICE_HISTORICAL_DATA!: Record<string, { x: string; y: number }[]>;

  requestStatus: ActionMessage | null = null;
  isLoading: boolean = false;
  charUnit = "minute";
  chartStepSize = 1;
  charType = "time";

  intervalLatestInformation: Array<number> = [];

  selectOptions: Array<selectItem> = [
    {
      label: this.translateLabels("options.1H"),
      value: "1h"
    },
    {
      label: this.translateLabels("options.24H"),
      value: "1d"
    },
    {
      label: this.translateLabels("options.1W"),
      value: "1w"
    },
    {
      label: this.translateLabels("options.1M"),
      value: "1m"
    }
    /* option for the view of year should be disabled for now #4419
    {
      label: this.translateLabels("options.1Y"),
      value: "1y"
    } */
  ];

  selectedLabelsOption: selectItem = {
    label: this.translateLabels("options.1H"),
    value: "1h"
  };

  ctx: HTMLElement | null = document.getElementById("line-chart");
  bgColors = {
    batteryVoltage: "rgba(56, 126, 65, 0.25)",
    solarArray: "rgba(224, 164, 0, 0.25)",
    chargingCurrent: "rgba(32, 167, 216, 0.25)",
    dischargingCurrent: "rgba(229, 4, 0, 0.25)",
    brightness: "rgba(239, 239, 239, 0.25)"
  };
  chartStyles: ChartBaseStyles = {
    batteryVoltage: {
      label: this.translateLabels("labels.batteryVoltage"),

      // data: Array.from(Array(dataPoints).keys(), () => Math.floor(Math.random() * 100 + 1)),
      data: [],
      fill: true,
      strokeColor: "#387e41",
      borderColor: "#387e41",
      pointBackgroundColor: "#387e41",
      backgroundColor: "rgba(56, 126, 65, 0.25)",
      // backgroundColor: this.generateGradient("rgba(56, 126, 65, 0.25)") as any,
      borderWidth: 1,
      tension: 0,
      pointStyle: "circle",
      pointRadius: 2,
      pointHoverRadius: 5,
      hidden: false
    },
    solarArray: {
      label: this.translateLabels("labels.solarArray"),
      // data: Array.from(Array(dataPoints).keys(), () => Math.floor(Math.random() * 100 + 1)),
      data: [],
      fill: true,
      strokeColor: "#E0A400",
      borderColor: "#E0A400",
      pointBackgroundColor: "#E0A400",
      backgroundColor: "rgba(224, 164, 0, 0.25)",
      // backgroundColor: this.generateGradient("rgba(224, 164, 0, 0.25)") as any,
      borderWidth: 1,
      tension: 0,
      pointStyle: "circle",
      pointRadius: 2,
      pointHoverRadius: 5,
      hidden: false
    },
    chargingCurrent: {
      label: this.translateLabels("labels.chargingCurrent"),
      // data: Array.from(Array(dataPoints).keys(), () => Math.floor(Math.random() * 100 + 1)),
      data: [],
      fill: true,
      strokeColor: "#20a8d8",
      borderColor: "#20a8d8",
      pointBackgroundColor: "#20a8d8",
      backgroundColor: "rgba(32, 167, 216, 0.25)",
      // backgroundColor: this.generateGradient("rgba(32, 167, 216, 0.25)") as any,
      borderWidth: 1,
      tension: 0,
      pointStyle: "circle",
      pointRadius: 2,
      pointHoverRadius: 5,
      hidden: false
    },
    dischargingCurrent: {
      label: this.translateLabels("labels.dischargingCurrent"),
      // data: Array.from(Array(dataPoints).keys(), () => Math.floor(Math.random() * 100 + 1)),
      data: [],
      fill: true,
      strokeColor: "#E50200",
      borderColor: "#E50200",
      pointBackgroundColor: "#E50200",
      backgroundColor: "rgba(229, 4, 0, 0.25)",
      // backgroundColor: this.generateGradient("rgba(229, 4, 0, 0.25)") as any,
      borderWidth: 1,
      tension: 0,
      pointStyle: "circle",
      pointRadius: 2,
      pointHoverRadius: 5,
      hidden: false
    },
    brightness: {
      label: this.translateLabels("labels.brightness"),
      // data: Array.from(Array(dataPoints).keys(), () => Math.floor(Math.random() * 100 + 1)),
      data: [],
      fill: true,
      strokeColor: "#EFEFEF",
      borderColor: "#EFEFEF",
      pointBackgroundColor: "#EFEFEF",
      backgroundColor: "rgba(239, 239, 239, 0.25)",
      // backgroundColor: this.generateGradient("rgba(239, 239, 239, 0.25)") as any,
      borderWidth: 1,
      tension: 0,
      pointStyle: "circle",
      pointRadius: 2,
      pointHoverRadius: 5,
      hidden: false
    }
  };

  /*----------  Watchers  ----------*/

  @Watch("selectedLabelsOption", { immediate: false })
  async HandleSelectedChange(s: selectItem) {
    this.FETCH_DEVICE_HISTORICAL_DATA({ deviceId: this.deviceId, timeFrame: s.value });
    const keyToRemoveInterval = ["1w", "1m", "1y"];
    if (keyToRemoveInterval.includes(s.value)) {
      await this.clearInterval();
      this.$emit("interval-data", 0);
    } else if (!this.intervalId) {
      await this.HandleRefreshDate();
    }
  }

  /*----------  Vue life cycles  ----------*/
  async created() {
    if (this.CURRENT_THEME === "light") {
      this.bgColors.brightness = "rgba(0, 0, 0, 0.25)";
      this.chartStyles.brightness.strokeColor = "#000000";
      this.chartStyles.brightness.borderColor = "#000000";
      this.chartStyles.brightness.pointBackgroundColor = "#000000";
    }
  }

  /*----------  Computed  ----------*/

  get formattedData(): DataPoints[] {
    return Object.keys(this.DEVICE_HISTORICAL_DATA).map((key) => ({
      // @ts-ignore
      ...this.chartStyles[key],
      // @ts-ignore
      backgroundColor: this.generateGradient(this.bgColors[key]) as any,
      data: this.DEVICE_HISTORICAL_DATA[key]
    }));
  }

  get labels() {
    return this.DEVICE_HISTORICAL_DATA.batteryVoltage?.map((i) => i.x);
  }

  /*----------  Vue life cycles  ----------*/
  beforeDestroy() {
    this.RESET_DEVICE_HISTORICAL_DATA();
    this.clearInterval();
  }

  /*----------  Methods  ----------*/
  async HandleRefreshDate() {
    if (this.intervalId) return;
    const MINUTE = 60 * 1000;
    let newInterval = setInterval(async () => {
      try {
        this.isLoading = true;
        await this.FETCH_DEVICE_HISTORICAL_DATA({
          deviceId: this.deviceId,
          timeFrame: this.selectedLabelsOption.value
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    }, MINUTE);

    this.intervalLatestInformation.push(newInterval);
    this.$emit("interval-data", newInterval);
  }

  async clearInterval() {
    let promises = this.intervalLatestInformation.map(async (intervalId: number) => {
      clearInterval(intervalId);
    });
    await Promise.all(promises);
    this.intervalLatestInformation = [];
  }

  async handleUpdateMetricData() {
    await this.FETCH_DEVICE_HISTORICAL_DATA({ deviceId: this.deviceId, timeFrame: this.selectedLabelsOption.value });
    this.ctx = document.getElementById("line-chart");
    await this.HandleRefreshDate();
  }

  generateGradient(color: string) {
    const item = (this.ctx as HTMLCanvasElement)?.getContext("2d");

    const G = item?.createLinearGradient(0, 0, 0, 600);
    G?.addColorStop(0.01, color);
    G?.addColorStop(0.8, "transparent");

    return G;
  }

  HandleSelectChange() {
    this.charType = "time";
    this.chartStepSize = 1;

    switch (this.selectedLabelsOption.value) {
      case "1d":
        this.charUnit = "minute";
        this.chartStepSize = 1;
        break;
      case "1w":
        this.charUnit = "hour";
        this.chartStepSize = 1;

        break;
      case "1m":
        this.charUnit = "day";
        break;
      /*option for the view of year should be disabled for now #4419
      case "1y":
        this.charUnit = "day";
        break;*/

      default:
        this.charUnit = "minute";
        break;
    }
  }

  translateLabels(key: string): TranslateResult {
    return this.$t(`project.section.informationModal.metricData.${key}`);
  }

  HandleLabelToggle({ labelIndex, state }: { labelIndex: number; state: boolean }) {
    Object.keys(this.chartStyles).forEach((key: string, i: number) => {
      if (i === labelIndex) {
        this.chartStyles[key as keyof ChartBaseStyles].hidden = state;
      }
    });
  }
}
