
import Section from "@/model/ModelSection";
import SectionState, { SingleDeviceState } from "@/model/ModelSectionState";
import Project, { TrafficCountStats } from "@/model/Project";
/**
 * Information tab view for the information modal overview
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Prop, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";

import InfOverViewCard from "@/components/project/SingleProject/Schematic/InformationModal/InfOverViewCard.vue";
import DesireStateVSActualStateTable from "@/components/project/SingleProject/Schematic/InformationModal/DesireStateVSActualStateTable.vue";
import cameraSectionImageModal from "../Camera/CameraSectionImageModal.vue";
import { AxiosResponse } from "axios";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import ReportGeneratorModal from "@/components/project/SingleProject/Report/ReportGeneratorModal.vue";
import UserAccess from "@/model/User/UserAccess";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { calculateDeviceStatus, isTheScreenOff, getConnectionStrengthImageName } from "@/utils/DeviceHelper";
import { MainboardAndRouterUrl, OverViewLabels } from "@/model/SingleDeviceModal";
import InformationTabOverviewCard from "./InformationTabOverviewCard.vue";
import InformationVWA from "@/components/project/SingleProject/Schematic/InformationModal/InformationVWA.vue";

@Component({
  name: "InformationTab",
  computed: {
    ...mapGetters(["isUserAdmin", "getUserAccess"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentSectionState: "GetCurrentSectionState",
      currentSectionDetails: "GetCurrentSectionDetails",
      GET_CURRENT_PICTURE: "GetCurrentPicture",
      CURRENT_TRAFFIC_STATS: "GetLatestTrafficCount"
    })
  },
  methods: {
    ...mapMutations("projects", {
      RESET_CAMERA_PICTURE: "ResetCameraSectionLatestPicture"
    }),
    ...mapActions("projects", {
      GetCurrentSectionState: "GetCurrentSectionState",
      getSectionDetailsFromBackEnd: "GetSectionDetail",
      GET_CAMERA_IDENTIFIER: "GetCameraSectionIdentifier",
      GET_CAMERA_LATEST_PICTURE: "GetCameraSectionLatestPicture",
      RESET_TRAFFIC_COUNTING: "ResetTrafficCounting",
      GET_LATEST_COUNTER: "GetTrafficCounter"
    })
  },
  components: {
    InformationVWA,
    InfOverViewCard,
    DesireStateVSActualStateTable,
    cameraSectionImageModal,
    ReportGeneratorModal,
    InformationTabOverviewCard
  }
})
export default class InformationTab extends Mixins(confirmModal) {
  /*----------  Props  ----------*/
  @Prop({ required: true, type: String }) boardPosition!: string;
  @Prop({ type: Boolean, default: false }) trafficReport!: boolean;

  /*----------  Vuex  ----------*/
  readonly GET_CURRENT_PICTURE!: any;
  readonly currentProject!: Project;
  readonly currentSectionDetails!: Section;
  readonly currentSectionState!: SectionState;
  private readonly isUserAdmin!: boolean;
  readonly getUserAccess!: UserAccess;
  readonly CURRENT_TRAFFIC_STATS!: TrafficCountStats;

  private GET_CAMERA_LATEST_PICTURE!: Function;
  private RESET_CAMERA_PICTURE!: Function;
  private RESET_TRAFFIC_COUNTING!: Function;
  private GET_LATEST_COUNTER!: Function;
  private GET_CAMERA_IDENTIFIER!: Function;
  private GetCurrentSectionState!: Function;
  private getSectionDetailsFromBackEnd!: Function;

  /*----------  Local data  ----------*/

  isLoading = false;
  updateStatus: null | ActionMessage = null;
  overViewLabels: OverViewLabels = {
    mainboardUid: "No information",
    ping: null,
    connectionQuality: null,
    iccId: "No information",
    routerSerialNumber: "No information",
    gpsPosition: null,
    position: "No information",
    version: "No information",
    lastSeen: "No information"
  };
  measureField = [
    {
      key: "name",
      sortable: false,
      label: this.translateTableLabel("name"),
      thStyle: { width: "6rem" }
    },
    {
      key: "currentQuantity",
      sortable: false,
      label: this.translateTableLabel("currentQuantity")
    },
    {
      key: "quantity",
      sortable: false,
      label: this.translateTableLabel("totalQuantity")
    },
    {
      key: "averageSpeed",
      sortable: false,
      label: this.translateTableLabel("averageSpeed")
    },
    {
      key: "occupancy",
      sortable: false,
      label: this.translateTableLabel("occupancy")
    }
  ];

  trafficCountVolumesField = [
    {
      key: "totalVolumePerHour",
      sortable: false,
      label: this.translateTableLabel("totalVolumePerHour")
    },
    {
      key: "carSimilarVolumePerHour",
      sortable: false,
      label: this.translateTableLabel("carSimilarVolumePerHour")
    },
    {
      key: "truckSimilarVolumePerHour",
      sortable: false,
      label: this.translateTableLabel("truckSimilarVolumePerHour")
    }
  ];

  isImageMaximized: boolean = false;

  openRows: { [key: string]: any } = {};
  currentDeviceStatus = "ALERT";

  webUrl = process.env.VUE_APP_WEB_URL;

  /*----------  computed  ----------*/
  get mainBoardReasons(): SingleDeviceState | null {
    switch (this.boardPosition) {
      case "deviceOne":
        return this.currentSectionState.stateDetails.deviceStateOne;
      case "deviceTwo":
        return this.currentSectionState.stateDetails.deviceStateTwo;
      default:
        return null;
    }
  }

  get overViewTable(): OverViewLabels {
    if (this.currentSectionState.stateDetails && this.mainBoardReasons) {
      let {
        mainboardUid,
        signalLevelAsu,
        signalLevelDbm,
        rsrp,
        rsrq,
        iccId,
        routerSerialNumber,
        gpsPosition,
        version,
        lastSeen,
        status,
        latencyMainboard,
        latencyRouter
      } = this.mainBoardReasons;
      let { kilometrage } = this.currentSectionDetails;
      this.currentDeviceStatus = status;

      this.overViewLabels = {
        mainboardUid,
        ping: {
          latencyMainboard: latencyMainboard,
          latencyRouter: latencyRouter
        },
        connectionQuality: {
          signalLevelAsu: signalLevelAsu,
          signalLevelDbm: signalLevelDbm,
          rsrp: rsrp,
          rsrq: rsrq
        },
        iccId,
        routerSerialNumber,
        gpsPosition: gpsPosition,
        position: `km ${kilometrage}`,
        version,
        lastSeen: this.$d(new Date(lastSeen), "med")
      };
    }
    return this.overViewLabels;
  }

  get urlsForMainboardAndRouter(): MainboardAndRouterUrl | null {
    if (this.isUserAdmin) {
      let { routerUrl, mainboardUrl } = this.mainBoardReasons
        ? this.mainBoardReasons
        : { routerUrl: null, mainboardUrl: null };
      return {
        routerUrl,
        mainboardUrl
      };
    } else {
      return null;
    }
  }

  get formattedActualState() {
    let informationFormatted = {
      picture: "",
      pictureTop: "",
      pictureMid: "",
      pictureBottom: "",
      pictureText: "",
      pictureTextSize: "",
      brightness: "",
      brightnessAuto: "",
      size: "",
      slaveboardPosition: "",
      slaveboardStatus: ""
    };
    if (this.currentSectionState.stateDetails && this.mainBoardReasons) {
      let {
        picture,
        pictureTop,
        pictureMid,
        pictureBottom,
        pictureText,
        pictureTextSize,
        brightness,
        brightnessAuto,
        slaveboardPosition,
        slaveboardStatus,
        height,
        width,
        deviceBatteryState,
        status,
        online
      }: any = this.mainBoardReasons;
      informationFormatted = {
        picture: isTheScreenOff(calculateDeviceStatus(deviceBatteryState?.batteryTerminalValue) || 0, status, online)
          ? ""
          : picture?.replace(".gif", "").replace(".bmp", ""),
        pictureTop: isTheScreenOff(calculateDeviceStatus(deviceBatteryState?.batteryTerminalValue) || 0, status, online)
          ? ""
          : pictureTop?.replace(".gif", "").replace(".bmp", ""),
        pictureMid: isTheScreenOff(calculateDeviceStatus(deviceBatteryState?.batteryTerminalValue) || 0, status, online)
          ? ""
          : pictureMid?.replace(".gif", "").replace(".bmp", ""),
        pictureBottom: isTheScreenOff(calculateDeviceStatus(deviceBatteryState?.batteryTerminalValue) || 0, status, online)
          ? ""
          : pictureBottom?.replace(".gif", "").replace(".bmp", ""),
        pictureText,
        pictureTextSize,
        brightness,
        brightnessAuto,
        slaveboardPosition,
        slaveboardStatus,
        size: `${width ? width : 0}x${height ? height : 0}`
      };
      if (!this.currentProject.isFPU) {
        // @ts-ignore
        delete informationFormatted.brightness;
      }
    }
    return informationFormatted;
  }

  get formattedDesiredState() {
    let formattedData = {
      picture: ``,
      pictureTop: ``,
      pictureMid: ``,
      pictureBottom: ``,
      pictureText: "",
      pictureTextSize: "",
      brightness: "",
      brightnessAuto: false,
      size: "",
      slaveboardPosition: 1,
      slaveboardStatus: "active"
    };
    let displayType: any =
      this.boardPosition === "deviceOne"
        ? this.currentSectionDetails.displayOneType
        : this.currentSectionDetails.displayTwoType;
    let size = displayType ? `${displayType.width}x${displayType.height}` : "";

    if (this.currentSectionState.stateDetails && this.mainBoardReasons) {
      let { state } = this.currentSectionDetails;

      let settings = this.currentProject.isCWU
        ? state.cwuSettings
        : this.currentProject.isVSU
        ? state.vsuSettings
        : this.currentProject.isRZP
        ? state.rzuSettings
        : this.currentProject.isSCENE
        ? state.sceneSettings
        : state.fpuSettings;

      if(this.currentSectionDetails.isVwa) {
        settings = state.vwaSettings;
      }

      let { brightness, brightnessOverride, filename, filenameTop, filenameMid, filenameBottom, text, textSize }: any = settings ? settings : formattedData;
      filename = filename && typeof filename === "string" ? filename.replace(".bmp", "") : filename;
      filenameTop = filenameTop && typeof filenameTop === "string" ? filenameTop.replace(".bmp", "") : filenameTop;
      filenameMid = filenameMid && typeof filenameMid === "string" ? filenameMid.replace(".bmp", "") : filenameMid;
      filenameBottom = filenameBottom && typeof filenameBottom === "string" ? filenameBottom.replace(".bmp", "") : filenameBottom;
      formattedData = {
        picture: `${filename ? filename : ""}`,
        pictureTop: `${filenameTop ? filenameTop : ""}`,
        pictureMid: `${filenameMid ? filenameMid : ""}`,
        pictureBottom: `${filenameBottom ? filenameBottom : ""}`,
        pictureText: text,
        pictureTextSize: textSize,
        brightness,

        brightnessAuto: !brightnessOverride,
        size,
        slaveboardPosition: 1,
        slaveboardStatus: "active"
      };
      if (!this.currentProject.isFPU) {
        // @ts-ignore
        delete formattedData.brightness;
      }
    }
    return formattedData;
  }

  get getSlaveBoardId(): number | string {
    if (this.currentSectionState.stateDetails && this.mainBoardReasons) {
      return this.mainBoardReasons?.slaveboardUid;
    } else return 0;
  }

  get baterryValue() {
    if (this.mainBoardReasons) {
      let batteryValue = this.mainBoardReasons.deviceBatteryState?.batteryTerminalCurrent;

      return batteryValue ? batteryValue : "0";
    } else {
      return "0";
    }
  }

  get updatedMainInformationCards() {

    let {
      online,
      status,
      picture,
      deviceBatteryState,
      prewarningLamp,
      cbRadio,
      width,
      height,
      brightness,
      radarData,
      supportEnabled,
      signalLevelAsu,
      provider,
      netType
    }: //extract radar data
    any = this.mainBoardReasons;
    const batteryStatus = calculateDeviceStatus(deviceBatteryState?.batteryTerminalValue) || 0;
    const isOff = isTheScreenOff(batteryStatus, status, online);

    const connectionStrengthImageName = getConnectionStrengthImageName(signalLevelAsu) || 0;

    // Check the desired picture  and show the desired picture if the section is waiting
    picture = status === "WAITING" ? this.getDesiredPicture() : picture;
    width = status === "WAITING" ? this.getDesiredSize().width : width;
    height = status === "WAITING" ? this.getDesiredSize().height : height;
    brightness = status === "WAITING" ? "" : brightness;

    let cbRadioCheck = this.checkCbRadio({ cbRadio, prewarningLamp });

    /*=====  Display type of devices formatted data  ======*/

    let mainInformationCard: any = {
      status: {
        path: `/img/informationModal/${
          status === "OK" ? "online.svg" : status === "WAITING" ? "waitingState.svg" : "statusWarning.svg"
        }`,
        value: supportEnabled
          ? "Support"
          : status === "OK"
          ? this.currentSectionState.cwuSettings?.manualOverride || this.currentSectionState.vsuSettings?.manualOverride
            ? "MANUELL"
            : "Ok"
          : status === "WARNING"
          ? "warning"
          : status === "WAITING"
          ? "waiting"
          : "Alert",
        bgColor: supportEnabled
          ? "bg-support"
          : status === "OK"
          ? this.currentSectionState.cwuSettings?.manualOverride || this.currentSectionState.vsuSettings?.manualOverride
            ? "bg-manuel"
            : "bg-success-light text-white"
          : status === "WARNING"
          ? "bg-warning"
          : status === "WAITING"
          ? "bg-edit-btn text-light"
          : "bg-danger"
      },
      mainboardStatus: {
        path: `/img/informationModal/onlineStrength${connectionStrengthImageName}.svg`,
        value: online ? "online" : "offline",
        bgColor: "",
        provider: provider,
        netType: netType
      },
      display: {
        path: `${this.webUrl}/img/${width ? width : "48"}x${height ? height : "48"}/${
          !isOff && picture ? picture : "noPicture.jpg"
        }`,
        value: "",
        bgColor: "bg-dark",
        measure: {},
        alt: !isOff && picture ? picture : "",
        brightnessValue: brightness ? `${brightness}%` : "",
        height: height ? height.toString() : "48",
        width: width ? width.toString() : "48",
        vwa: {
          isVwa: false,
          vwaSettings: this.currentSectionDetails.state.vwaSettings
        }
      },
      batteryStatus: {
        path: "/img/informationModal/battery.svg",
        value: deviceBatteryState ? deviceBatteryState.batteryTerminalCurrent : "",
        bgColor: "",
        battery: deviceBatteryState?.batteryTerminalValue,
        batteryClass: batteryStatus,
        batteryNumber:
          this.boardPosition === "deviceOne"
            ? this.currentSectionDetails.deviceOneBatteryNumber
            : this.currentSectionDetails.deviceTwoBatteryNumber
      },
      prewarningLamp: {
        path: "/img/informationModal/prewarning.svg",
        value: !isOff && prewarningLamp ? this.$t("project.utils.on") : this.$t("project.utils.off"),
        bgColor: isOff || cbRadioCheck.checkPrewarning ? "" : "bg-warning",
        measure: {}
      },
      cbRadio: {
        path: "/img/informationModal/cbradio.svg",
        value: !isOff && cbRadio ? this.$t("project.utils.on") : this.$t("project.utils.off"),
        bgColor: isOff || cbRadioCheck.checkCbRadio ? "" : "bg-warning",
        measure: {}
      }
    };

    /*=====  Measure or Radar type of devices formatted data  ======*/
    if(this.currentSectionDetails.isVwa) {
      mainInformationCard.display.vwa.isVwa = true;
    }

    if (this.currentSectionDetails.isMeasure) {
      //@ts-ignore
      delete mainInformationCard.prewarningLamp;
      //@ts-ignore
      delete mainInformationCard.cbRadio;

      let checkMeasureSettings = this.measureOrCameraSettingsChecker(prewarningLamp, cbRadio, "measure");

      mainInformationCard.display = {
        path: ``,
        value: "",
        bgColor: "",
        measure: radarData ? this.formatRadarDataCard(radarData) : {}
      };
      mainInformationCard.lane_2 = {
        path: "",
        value: !isOff && prewarningLamp ? this.$t("project.utils.on") : this.$t("project.utils.off"),
        bgColor: isOff || checkMeasureSettings.one ? "" : "bg-warning"
      };
      mainInformationCard.lane_3 = {
        path: "",
        value: !isOff && cbRadio ? this.$t("project.utils.on") : this.$t("project.utils.off"),
        bgColor: isOff || checkMeasureSettings.two ? "" : "bg-warning"
      };
    }

    /*=====  Camera type of devices formatted data  ======*/

    if (this.isCameraSection) {
      this.getCameraSectionImage();
      let checkSettings = this.measureOrCameraSettingsChecker(prewarningLamp, cbRadio, "camera");
      //@ts-ignore
      delete mainInformationCard.prewarningLamp;
      //@ts-ignore
      delete mainInformationCard.cbRadio;

      mainInformationCard.display = {
        path: "",
        value: "",
        bgColor: "",
        measure: {},
        cameraImage: this.GET_CURRENT_PICTURE ? this.GET_CURRENT_PICTURE : `/img/64x96/noPicture.jpg`
      };

      mainInformationCard.outputOne = {
        path: "",
        value: !isOff && prewarningLamp ? this.$t("project.utils.on") : this.$t("project.utils.off"),
        bgColor: isOff || checkSettings.one ? "" : "bg-warning",
        measure: {}
      };
      mainInformationCard.outputTwo = {
        path: "",
        value: !isOff && cbRadio ? this.$t("project.utils.on") : this.$t("project.utils.off"),
        bgColor: isOff || checkSettings.two ? "" : "bg-warning",
        measure: {}
      };
    }

    return mainInformationCard;
  }

  get updatedSecondaryInformationCard() {
    let { deviceBatteryState, doorContact, brightnessAuto, housingTemperature }: any = this.mainBoardReasons;
    let secondaryInformationCard: any = {
      chargingCurrent: {
        path: "/img/informationModal/charging.svg",
        value: deviceBatteryState ? deviceBatteryState.chargingCurrent : "",
        bgColor: ""
      },
      dischargingCurrent: {
        path: "/img/informationModal/charging.svg",
        value: deviceBatteryState ? deviceBatteryState.dischargingCurrent : "",
        bgColor: ""
      },
      solarArray: {
        path: "/img/informationModal/solarPanel.svg",
        value: deviceBatteryState ? deviceBatteryState.solarArray : "",
        solarPanelNumber: deviceBatteryState
          ? this.boardPosition === "deviceOne"
            ? this.currentSectionDetails.deviceOneSolarPanelNumber
            : this.currentSectionDetails.deviceTwoSolarPanelNumber
          : "",
        bgColor: ""
      },
      housingTemperature: {
        path: "/img/informationModal/houseTempeture.svg",
        value: housingTemperature,
        bgColor: ""
      },
      doorContact: {
        path: `/img/informationModal/${doorContact ? "doorClose.svg" : "door.svg"}`,
        value: doorContact ? this.$t("project.utils.close") : this.$t("project.utils.open"),
        bgColor: ""
      },
      brightnessAuto: {
        path: "/img/informationModal/switch.svg",
        value: brightnessAuto ? this.$t("project.utils.auto") : this.$t("project.utils.manual"),
        bgColor: ""
      }
    };
    return secondaryInformationCard;
  }

  get isMeasureSection() {
    return this.currentSectionDetails.isMeasure;
  }

  get sectionMeasures() {
    if (this.currentSectionState.stateDetails && this.mainBoardReasons) {
      let radarData: Array<any> = this.mainBoardReasons.radarData;
      radarData = radarData.map((item: any) => {
        if (this.openRows[item.name]) {
          this.$set(item, "_showDetails", true);
        } else {
          this.$set(item, "_showDetails", false);
        }
        return item;
      });
      return radarData;
    } else {
      return [];
    }
  }

  get trafficCountVolumesItems() {
    const totalVolumePerHour = this.CURRENT_TRAFFIC_STATS.totalVolumePerHour
      ? this.CURRENT_TRAFFIC_STATS.totalVolumePerHour
      : 0;
    const carSimilarVolumePerHour = this.CURRENT_TRAFFIC_STATS.carSimilarVolumePerHour
      ? this.CURRENT_TRAFFIC_STATS.carSimilarVolumePerHour
      : 0;
    const truckSimilarVolumePerHour = this.CURRENT_TRAFFIC_STATS.truckSimilarVolumePerHour
      ? this.CURRENT_TRAFFIC_STATS.truckSimilarVolumePerHour
      : 0;

    return [
      {
        totalVolumePerHour: totalVolumePerHour,
        carSimilarVolumePerHour: carSimilarVolumePerHour,
        truckSimilarVolumePerHour: truckSimilarVolumePerHour
      }
    ];
  }

  get isCameraSection() {
    return this.currentSectionDetails.isCamera;
  }

  get isVwaSection() {
      return this.currentSectionDetails.isVwa
  }

  get getReportPermission() {
    return (
      this.getUserAccess.Access.VIEW.project.report.generateRadarDataReport ||
      this.currentProject.trafficReport ||
      this.trafficReport
    );
  }

  /*----------  Methods  ----------*/
  formatRadarDataCard(radarData: Array<any>) {
    let DATA_RESULT = radarData[0];
    if (this.currentProject.isRZP && radarData[1]) {
      DATA_RESULT =
        this.getLaneSpeed(radarData[1].averageSpeed) < this.getLaneSpeed(radarData[0].averageSpeed) &&
        this.getLaneSpeed(radarData[1].averageSpeed) !== 0
          ? radarData[1]
          : radarData[0];
    }

    return DATA_RESULT;
  }

  getLaneSpeed(laneSpeed: String) {
    return parseFloat(laneSpeed.slice(0, -4));
  }

  async handleRefreshTheInformation() {
    this.isImageMaximized = false;
    this.isLoading = true;
    await this.getSectionDetailsFromBackEnd(this.currentSectionDetails.id);
    await this.GetCurrentSectionState(this.currentSectionDetails.id);
    this.isLoading = false;
  }

  getDesiredPicture() {
    let { state } = this.currentSectionDetails;
    let settings = this.currentProject.isCWU
      ? state.cwuSettings
      : this.currentProject.isVSU
      ? state.vsuSettings
      : this.currentProject.isRZP
      ? state.rzuSettings
      : this.currentProject.isSCENE
      ? state.sceneSettings
      : state.fpuSettings;
    let { filename }: any = settings || { filename: "" };

    return filename ? filename.value : filename ? filename : "";
  }

  getDesiredSize() {
    let displayTypes: any;
    if (this.boardPosition === "deviceOne") {
      displayTypes = this.currentSectionDetails.displayOneType;
    }
    if (this.boardPosition === "deviceTwo") {
      displayTypes = this.currentSectionDetails.displayTwoType;
    }
    let width = displayTypes ? displayTypes.width : this.currentProject.isRZP ? "64" : "48";
    let height = displayTypes ? displayTypes.height : this.currentProject.isRZP ? "96" : "48";
    return {
      width,
      height
    };
  }

  translateTableLabel(label: string) {
    return this.$t(`project.section.informationModalMeasureTable.${label}`);
  }

  getCameraSectionImage() {
    if (this.currentSectionDetails.isCamera && !this.currentProject.isArchived) {
      this.GET_CAMERA_IDENTIFIER(this.currentSectionDetails.id).then(async (res: AxiosResponse) => {
        if (res.status === 200 && res.data.cameraId !== -1 && res.data.cameraId !== null) {
          await this.GET_CAMERA_LATEST_PICTURE(res.data.cameraId);
        } else {
          this.RESET_CAMERA_PICTURE();
        }
      });
    }
  }

  handleMaximizeImage() {
    this.isImageMaximized = true;
  }

  checkCbRadio({ cbRadio = true, prewarningLamp = true }: { cbRadio: boolean; prewarningLamp: boolean }) {
    // Actual State cbRadio and prewarningLamp are passed as parameters to this methode
    if (this.currentProject.isFPU || this.currentProject.isCWU || this.currentProject.isVSU) {
      // Desired settings are stored in CurrentSectionDetails
      let { state } = this.currentSectionDetails;
      let settings: any = null;

      if(this.currentSectionDetails.type && this.currentSectionDetails.type?.name == 'vwa') {
        settings = state.vwaSettings;

      } else {
        settings = this.currentProject.isCWU
          ? state.cwuSettings
          : this.currentProject.isVSU
          ? state.vsuSettings
          : state.fpuSettings;
      }

      let { cbradioEnabled, prewarningEnabled, filename }: any = this.isCameraSection
        ? { cbradioEnabled: false, prewarningEnabled: false, filename: "" }
        : settings;
      /*----------  check if the project have  is cwu and have a file name attach to it the db and pre should always be true  ----------*/
      if (this.currentProject.isCWU) {
        let isTraffic =
          (state.cwuSettings?.customFilenameEnabled &&
            `${state.cwuSettings?.customFilenameTraffic}.bmp` === filename) ||
          filename === "Stau.bmp";
        let isTrafficRisk =
          (state.cwuSettings?.customFilenameEnabled &&
            `${state.cwuSettings?.customFilenameTrafficRisk}.bmp` === filename) ||
          filename === "Stgefahr.bmp";
        cbradioEnabled = isTraffic || isTrafficRisk ? true : false;
        prewarningEnabled = isTraffic || isTrafficRisk ? true : false;
      } else {
        // eslint-disable-next-line no-self-assign
        cbradioEnabled = cbradioEnabled;
        // eslint-disable-next-line no-self-assign
        prewarningEnabled = prewarningEnabled;
      }
      return {
        checkCbRadio: cbRadio === cbradioEnabled,
        checkPrewarning: prewarningLamp === prewarningEnabled
      };
    }
    return {
      checkCbRadio: true,
      checkPrewarning: true
    };
  }

  measureOrCameraSettingsChecker(prewarningLamp: boolean, cbRadio: boolean, sectionType: string) {
    let measureSettings =
      sectionType === "camera"
        ? this.currentSectionDetails.state.cameraSettings
        : this.currentSectionDetails.state.measureSettings;

    return {
      one: prewarningLamp === measureSettings.outputOne,
      two: cbRadio === measureSettings.outputTwo
    };
  }

  async HandleResetTrafficCount() {
    this.updateStatus = null;
    this.confirm(this.$t(`project.section.sectionState.resetTrafficCountConfirmMsg`).toString(), async () => {
      let ResetToNow = new Date(Date.now()).toISOString();

      let resetTraffic = await this.RESET_TRAFFIC_COUNTING({
        id: this.currentSectionDetails.id,
        from: ResetToNow,
        to: null,
        projectVolume: this.currentProject.volumeAnalysisVariant?.name || null
      });
      if (resetTraffic.status === 200) {
        this.updateStatus = {
          class: "success",
          msg: this.$t(`project.section.sectionState.resetTrafficCountSuccess`).toString()
        };
      } else {
        this.updateStatus = {
          class: "danger",
          msg: resetTraffic
        };
      }
    });
  }

  handleToggle(item: any) {
    this.$set(item, "_showDetails", !item._showDetails);
    if (item.name in this.openRows) {
      this.openRows[item.name] = !this.openRows[item.name];
      this.openRows = { ...this.openRows };
    } else {
      this.openRows[item.name] = true;
      this.openRows = { ...this.openRows };
    }
  }
}
