
/**
 * Information overview card
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import VwaLedSign from "@/components/project/SingleProject/Schematic/VwaLedSign.vue";

@Component({
  name: "InfOverViewCard",
  components: {VwaLedSign}
})
export default class InfOverViewCard extends Vue {
  @Prop({ type: String, required: true, default: "Mainboard Status" })
  header!: string;
  @Prop({ type: String, default: "online.svg" })
  path!: string;
  @Prop({ type: String, default: "online" })
  value!: string;
  @Prop({ type: String })
  bgColor!: string;
  @Prop({ type: String, default: "" })
  provider!: String;
  @Prop({ type: String, default: "" })
  netType!: String;
  @Prop({ type: Object, default: () => ({}) })
  measure!: { name: string; averageSpeed: string; occupancy: string };
  @Prop({ type: String, default: "" })
  cameraImage!: string;
  @Prop({ type: Number, default: 0 })
  battery!: number;
  @Prop({ type: Number, default: 0 })
  batteryNumber!: number;
  @Prop({ type: String, default: "" })
  batteryClass!: String;
  @Prop({ type: String, default: "" })
  altText!: String;
  @Prop({ type: String, default: "" })
  brightnessValue!: String;
  @Prop({ type: String, default: "" })
  width!: number;
  @Prop({ type: String, default: "" })
  height!: number;
  @Prop({ default: 0}) solarPanelNumber!: any;
  @Prop({  }) vwa!: any;
  @Prop({ }) currentSectionDetails!: any;

  backgroundSizeMid: String = '64px';
  backgroundSizeHeight: String = '54px';

  HandleImageError(event: Event | any) {
    const src = event.target?.src;
    if (src.includes(".gif")) {
      event.target.src = src.replace(".gif", ".bmp");
    }
  }

  created() {
    if(this.vwa && this.vwa.vwaSettings) {
      if(this.vwa.vwaSettings.filenameMid.charAt(0) !== 'm') {
        this.backgroundSizeMid = '15px';
        this.backgroundSizeHeight = '60px';
      }
    }
  }

}
