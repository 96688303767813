
/**
 * Section settings tab for the information modal
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import Project from "@/model/Project";
import Section from "@/model/ModelSection";

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

import DisplayModalInterval from "@/components/project/SingleProject/Schematic/Interval/DisplayModalInterval.vue";
import CwuSettings from "@/components/project/SingleProject/Schematic/CwuSettings.vue";
import VwaSettings from "@/components/project/SingleProject/Schematic/VwaSettings.vue";
import FpuSettings from "@/components/project/SingleProject/Schematic/FpuSettings.vue";
import SceneSettings from "@/components/project/SingleProject/Schematic/SceneSettings.vue";
import CameraSectionSetting from "@/components/project/SingleProject/Schematic/Camera/CameraSectionSetting.vue";
import CameraAndMeasureSettings from "@/components/project/SingleProject/Schematic/Settings/CameraAndMeasureSettings.vue";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { ProjectTypeInterfaceInformationModal } from "@/model/MainProject/MainProjectSection";

@Component({
  name: "SectionSettingsTab",
  components: {
    DisplayModalInterval,
    CwuSettings,
    VwaSettings,
    FpuSettings,
    SceneSettings,
    CameraSectionSetting,
    CameraAndMeasureSettings
  },
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentSectionDetails: "GetCurrentSectionDetails"
    })
  }
})
export default class SectionSettingsTab extends Vue {
  /*----------  vuex  ----------*/
  readonly currentProject!: Project;
  readonly currentSectionDetails!: Section;
  @Prop({ type: Object as () => ProjectTypeInterfaceInformationModal })
  projectType!: ProjectTypeInterfaceInformationModal;
  @Prop({ type: String }) boardPosition!: string;

  statusMessage: null | ActionMessage = null;
  HandleUpdateCamera(status: ActionMessage) {
    this.statusMessage = status;
  }
  get Type(): Project | ProjectTypeInterfaceInformationModal {
    if (this.projectType) {
      return this.projectType;
    }
    return this.currentProject;
  }

  get DisplaySize(): string {
    return this.boardPosition === "deviceOne"
      ? this.currentSectionDetails.deviceOneSize
      : this.currentSectionDetails.deviceTwoSize;
  }
  /*----------  Local data  ----------*/

  refreshSchematicView() {
    this.$emit("refresh-schematic-view");
  }
}
