

import { Component, Vue, Prop} from "vue-property-decorator";
import {TableFields} from "@/model/Tables/TableFields";
import {SingleDeviceState} from "@/model/ModelSectionState";

@Component({
  name: "IOTab",
})
export default class IOTab extends Vue {

  @Prop({}) currentSectionDetails!: any;
  @Prop({ required: true, type: String }) boardPosition!: string;

  fields: Array<TableFields> = [
    {
      key: "description",
      sortable: true,
      label: this.translateTableLabel("description")
    },
    {
      key: "actualState",
      sortable: true,
      label: this.translateTableLabel("actualState")
    }
  ];

  translateTableLabel(label: string) {
    return this.$t(`project.section.informationModalTableLabel.${label}`);
  }

  createDeviceStateDetails(deviceState: any) {
    const details: any = {};
    for (let i = 1; i <= 8; i++) {
      details[`in${i}`] = deviceState[`in${i}`];
      details[`out${i}`] = deviceState[`out${i}`];
    }
    return details;
  }

  getIOData(): Array<any> {

    // Workaround because can be key-names which starts with "in" or "out", example "outline"+

    var device = this.currentSectionDetails.state.stateDetails;

    if(this.boardPosition == 'deviceOne') {
      device = device.deviceStateOne;
    } else {
      device = device.deviceStateTwo;
    }

    var insOuts = this.createDeviceStateDetails(device);


    // Sort the keys alphabetic
    const sortedInsOuts = Object.keys(insOuts).sort();

    var res: any = [];
    var countIns = 1;
    var countOut = 1;

    for(const key in sortedInsOuts) {

      var name = "";
      if(sortedInsOuts[key].slice(0, -1) == 'in') {
        name = `IN${countIns}`;
        countIns++;

      } else if(sortedInsOuts[key].slice(0, -1) == 'out') {
        name = `OUT${countOut}`;
        countOut++;

      }

      if( this.$t(`project.section.ioTab.${sortedInsOuts[key]}`) != '' ) {
        res.push({
          description: `${name} - ${this.$t(`project.section.ioTab.${sortedInsOuts[key]}.description`)} `,
          actualState: `${this.$t(`project.section.ioTab.${sortedInsOuts[key]}.value.${insOuts[sortedInsOuts[key]]}`)}`
        })
      }
    }

    res.push(
      {
        description: `IN - ${this.$t(`project.section.ioTab.deviceStatusIn`)} `,
        actualState: (device.deviceStatusIn === 'active') ? this.$t(`project.section.ioTab.deviceStatusInActive`) : this.$t(`project.section.ioTab.deviceStatusInInactive`)
      },
      {
        description: `OUT - ${this.$t(`project.section.ioTab.deviceStatusOut`)} `,
        actualState: (device.deviceStatusOut === 'active') ? this.$t(`project.section.ioTab.deviceStatusOutActive`) :  this.$t(`project.section.ioTab.deviceStatusOutInactive`)
      },
    )

    // alphabetic sort
    return this.sortItemsByDescription(res);

  }

  sortItemsByDescription(array: any) {

    return array.sort((a: any, b: any) => {
      // Vergleiche die Werte von "description" und sortiere sie alphabetisch
      return a.description.localeCompare(b.description);
    });

  }

}
